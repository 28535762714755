/* eslint-disable vue/no-parsing-error */
<template>
    <div>
      <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="">
        <CRow>
        <CCol col="6" xl="6">
          <h6 class="w-100 text-muted">Obchod</h6>
           <h2 class="mb-0 page-title">Objednávky</h2>
        </CCol>
        <CCol col="6" xl="6">
          <div class="py-4 mt--4 mb--3">
                <h6 class="h2 text-white d-inline-block mb-0">Zoznam faktúr</h6>
                <div class="text-right" style="float:right">
                <a-select type="text" placeholder="Druh exportu" v-model="exportFormat" style="   display: inline-block;    width: 200px; margin-right: 10px;">
                   <a-select-option value="pohoda" key="pohoda">Softvér Pohoda</a-select-option>
                   <a-select-option value="pdf" key="pdf">PDF</a-select-option>
                </a-select>
            <a-button type="primary" size="large" @click="exportInvoices">
            Export
          </a-button>

            </div></div>
        </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>


          <div class="row">

                <div class="col">
        <div id="components-form-demo-advanced-search"> <a-form class="ant-advanced-search-form" :form="form"  @submit="handleFilter">
      <a-row :gutter="24">
        <a-col :span="2" style="block">
            <a-form-item label="Rok" :hasFeedback="false">
                        <a-select type="text"
                        v-decorator="[
                `selectedYear`,
                { initialValue: selectedYear }
              ]" >
                            <template v-for="year in exposeYear()">
                                <a-select-option :value="year" :key="year">{{year}}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-item>
                </a-col>
          <a-col :span="2" style="block">
                   <a-form-item label="Mesiac" :hasFeedback="false">
                        <a-select type="text"       v-decorator="[
                `selectedMonth`,
                { initialValue: selectedMonth }
              ]" >
                       <a-select-option value="" key="0">Celý rok</a-select-option>

                            <template v-for="month in 12">
                                <a-select-option :value="month" :key="month">{{new Date(selectedYear + '-' + month +'-1').toLocaleString('sk-SK',{month:'long'})}}</a-select-option>
                            </template>
                        </a-select>
                    </a-form-item>
        </a-col>
          <a-col :span="4" style="block">
           <a-form-item :hasFeedback="false" label="Číslo objednávky">
           <a-input class="w-100"
       v-decorator="[
                `orderNumber`
              ]"
      placeholder="Zadajte celé, alebo časť čísla objednávky"

    />
          </a-form-item>
        </a-col>
            <a-col :span="5" style="block">
           <a-form-item :hasFeedback="false" :hasSuccess="false"  label="Typ platby">
           <a-select
      mode="multiple"
       v-decorator="[
                `orderPayment`
              ]"
    >
      <a-select-option v-for="i in order_payments" :key="i.value">{{i.label}}</a-select-option>
    </a-select>
          </a-form-item>
        </a-col>
          <a-col :span="5" style="block">
           <a-form-item :hasFeedback="false"  label="Stav objednávky">
           <a-select
      mode="multiple"
       v-decorator="[
                `orderStatus`
              ]"
    >
      <a-select-option v-for="i in order_statuses" :key="i.value">{{i.label}}</a-select-option>
    </a-select>
          </a-form-item>
        </a-col>
     <a-col :span="5" style="block">
           <a-form-item :hasFeedback="false"  label="Fulltextové vyhľadávanie">
           <a-input
       v-decorator="[
                `fulltextSearch`
              ]"
      placeholder="Meno, priezvisko, adresa, email, telefón ..."
    >
    </a-input>
          </a-form-item>
        </a-col>



      </a-row>
      <a-row >
        <a-col :span="12" :style="{ textAlign: 'left', paddingLeft: '5px' }" v-if="data">
         <span class="text-muted " v-if="!loading">Výsledkov: <span class="text-bold">{{data.length}}</span></span>
        <span  class="text-muted ml-3" v-if="filteredprice > 0">Suma prijatá: <span class="text-bold">{{filteredprice.toLocaleString()}} €</span></span>
        <span  class="text-muted ml-3" v-if="filteredabbprice > 0">Opustené/na platbe: <span class="text-bold">{{filteredabbprice.toLocaleString()}} €</span></span>
        </a-col>
        <a-col :span="12" :style="{ textAlign: 'right' }">
          <a-button  @click="handleResetMF">
            Reštart
          </a-button>
          <a-button type="primary" :style="{ marginLeft: '8px' }" html-type="submit">
            Filtrovať
          </a-button>
        </a-col>
      </a-row>
    </a-form>
</div>
</div>
</div>



          <div class="bg-white" style="background-color:transparent;padding:0;border:0; margin:0; margin-top:30px">
                            <a-table
                                    :columns="columns"
                                    :dataSource="data"
                                     :pagination="pagination"
                                    :row-selection="rowSelection"
                                    size="small"
                                    :loading="loading"
                                    :rowKey="record => record.order_id"
                                    class="table-flush">
                                <div
                                        slot="filterDropdown"
                                        slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                                        style="padding: 8px"
                                >
                                    <a-input
                                            v-ant-ref="c => searchInput = c"
                                            :placeholder="`Search ${column.dataIndex}`"
                                            :value="selectedKeys[0]"
                                            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                                            @pressEnter="() => handleSearch(selectedKeys, confirm)"
                                            style="width: 188px; margin-bottom: 8px; display: block;"
                                    />
                                    <a-button
                                            type="primary"
                                            @click="() => handleSearch(selectedKeys, confirm)"
                                            icon="search"
                                            size="small"
                                            style="width: 90px; margin-right: 8px"
                                    >Search
                                    </a-button
                                    >
                                    <a-button @click="() => handleReset(clearFilters)" size="small" style="width: 90px"
                                    >Reset
                                    </a-button
                                    >
                                </div>
                                <a-icon
                                        slot="filterIcon"
                                        slot-scope="filtered"
                                        type="search"
                                        :style="{ color: filtered ? '#108ee9' : undefined }"
                                />
                                <template slot="customRender" slot-scope="text">
      <span v-if="searchText">
        <template
                v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
        >
          <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
          >{{fragment}}</mark
          >
          <template v-else>{{fragment}}</template>
        </template>
      </span>
                                    <template v-else
                                    >{{text}}
                                    </template
                                    >
                                </template>
                                <template slot="user_role" slot-scope="user">
                                    <a class="text-left text-lowercase" :href="'role/'+ role" :key="index" v-for="(role,index) in JSON.parse(user.type_id)"> {{roleops[role]}}<span
                                            v-if="index+1 < JSON.parse(user.type_id).length">, </span></a>
                                </template>
                                <template slot="products" slot-scope="products,record">
<template v-for="(product,ind) in record.abandoned">
                                    <div :key="ind" v-if="products[0].type === false" title="Produkt odstránený"  :class="'abandoned item-' + ind">
                                    {{product}}</div></template>
                                    <div :key="ind" v-for="(product,ind) in products" :class="'product item-' + ind">

                                        <div v-if="product.cttt !== 'voucher'">
                                            <h4 :class="'type-' + product.cttt"><b v-if="product.count > 1">{{product.count}} x </b><router-link class="text-dark" :to="'/edit-content/' + product.id">{{product.title}}</router-link></h4>
                                           <small> <template v-if="product.data.distribution_datas">{{ product.data.distribution_datas }}</template> {{product.data.ean}} -             <template v-if="product.data.skladombb">{{ product.data.skladombb }}</template>
                                            <template v-else>0</template> - {{ product.data.sklad }}</small>
                                        </div>
                                        <div v-else>
                                            <h4 v-if="product.formtype === 'pobytovy'" color="volcano" :key="product.cttt">
                                                Pobytový voucher
                                            </h4>
                                            <h4 v-if="product.formtype === 'wellness'" color="geekblue" :key="product.cttt">
                                                Wellness voucher
                                            </h4>
                                            <h4 v-if="product.formtype === 'namieru' || product.formtype === null" color="green" :key="product.cttt">
                                                Voucher na mieru
                                            </h4>
                                        </div>
                                    </div>
                                </template>
                                <template slot="phones" slot-scope="phone,record">
                                    {{record.email}}  {{phone}}
                                </template>
                                  <template slot="shipping" slot-scope="shipping">
                                    <a-tag  color="red" v-if="shipping.shipment == 'packeta-5' || shipping.shipment == 'packeta-10' || shipping.shipment == 'packeta-15'">Packeta</a-tag>
                                    <a-tag  color="green" v-if="shipping.shipment == 'dpd-5'">DPD</a-tag>
                                    <a-tag  color="blue" v-if="shipping.shipment == 'cod'">Osobný odber</a-tag>
                                 <a-tag  color="orange" v-if="shipping.shipment == 'balikadresa-5' || shipping.shipment == 'balikadresa-10' || shipping.shipment == 'balikadresa-15'">Slovenská pošta (balík)</a-tag>
                                 <a-tag  color="orange" v-if="shipping.shipment == 'balikposta-5' || shipping.shipment == 'balikposta-10' || shipping.shipment == 'balikposta-15'">Slovenská pošta</a-tag>
                                </template>
                                <template slot="status" slot-scope="payment">
                                    <div>
                                        <a-tag v-if="payment === 'pending'" color="green">
                                            Objednávka
                                        </a-tag>
                                        <a-tag v-if="payment === 'payment_recieved'"  color="orange">
                                            Prijatá platba
                                        </a-tag>
                                        <a-tag v-if="payment === 'in_checkout'"  color="">
                                            Platobná brána
                                        </a-tag>
                                        <a-tag v-if="payment === 'storno'"  color="red">
                                         Stornovaná
                                        </a-tag>
                                        <a-tag v-if="payment === 'abandoned'"  color="red">
                                            Opustená
                                        </a-tag>
                                        <a-tag v-if="payment === 'shiped'"  color="blue">
                                            Odoslaná
                                        </a-tag>
                                        <a-tag v-if="payment === 'instock'"  color="">
                                            Naskladnené
                                        </a-tag>

                                        <a-tag v-if="payment === 'completed'"  color="green">
                                            Kompletná
                                        </a-tag>

                                    </div>
                                </template>  <template slot="payment" slot-scope="payment,record">
                                    <div>
                                            <a-tag v-if="payment === 'cod'" color="">
                                            Dobierka
                                            </a-tag>
                                            <a-tag v-if="payment === 'payout'"  color="green">
                                            Karta
                                            </a-tag>
                                            <a-tag v-if="payment === 'cardpay'"  color="green">
                                            Karta
                                            </a-tag>
                                            <a-tag v-if="payment === 'webpay'"  color="green">
                                            Karta
                                            </a-tag>
                                            <a-tag v-if="payment === 'bank'"  color="red">
                                            Bankový prevod
                                            </a-tag>
                                            <a-tag color="orange"  v-if="payment === 'bank'"  >
                                            VS: {{record.vs}}
                                            </a-tag>
                                    </div>
                                </template>
<template slot="invoice" slot-scope="invoice,record">
<template v-if="invoice">
<a :href="url.API_URL + '/../../' + invoice.invoice" target="_blank"><a-button size="small" v-if="invoice">FA{{ invoice.invoice_number }}</a-button></a>
<a-button size="small" @click="redo(record.order_id,invoice.invoice_number,record.created)">redo</a-button>
</template>
</template>
                                <template slot="prices" slot-scope="price, record">
                                    <div v-if="price>0">{{Number(price).toFixed(2) + ' €'}}</div>
                                    <div v-if="record.transaction" class="paid" title="nastavená záloha">({{Number(record.transaction.amount).toFixed(2) + ' €'}})</div>
                                </template>
                                <template slot="date" slot-scope="datedata">
                                    <span>{{formatDate(datedata)}}</span>
                                </template>
                                <template slot="name" slot-scope="user">
                                    <router-link class="btn nopad text-default btn-icon btn-secondary btn-sm" :to="'/order/' + user.order_id">Detail</router-link>

                                   </template>
                            </a-table>
                             <div  style="bottom:0" class="action-panel w-25 m-3 float-left"  v-if="rowSelection.selectedRowKeys.length > 0">
                              <a-select style="width:150px;"  class="mr-3" size="small" placeholder="Vyberte si akciu" v-model="action" :options="actions"></a-select>
                              <a-popconfirm placement="topRight"
                                            @confirm="doAction"
                                            okText="Áno"
                                            cancelText="Nie"
                              >
                                  <template slot="title">
                                      <h5 color="danger" >Prajete si vykonať tento príkaz?</h5>
                                      Príkaz ovplyvní {{rowSelection.selectedRowKeys.length}} <span v-if="rowSelection.selectedRowKeys.length === 1">stránku</span> <span v-if="rowSelection.selectedRowKeys.length > 1 && rowSelection.selectedRowKeys.length < 5">stránky</span> <span v-if="rowSelection.selectedRowKeys.length > 4">stránok</span>.
                                  </template>
                                 <a-button type="warning" :loading="loadingExport">Vykonať</a-button>
                              </a-popconfirm>
                          </div>
                        </div>
                   </CCardBody>
                   </CCard>
                   </CCol>
                   </CRow>
                   </div>
</template>
<script>
    import {getURL} from "../api";

    import {IMG_URL} from "../api";
    import {dataService} from "../_services";
    import {Button, Input, Icon, Tag} from 'ant-design-vue';

    import moment from 'moment'

    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import locale from 'ant-design-vue/es/date-picker/locale/sk_SK';
    import 'moment/locale/sk';
    const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const formTwoLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 12 },
};

Vue.use(FormItem);
    var locale2 = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storočie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "MMMM YYYY"
    }
    export default {
        name: "deskreservations",
        components: {
            "a-button": Button,
            "a-icon": Icon,
            "a-tag": Tag,
            "a-input": Input
        },
        data() {
            return {
              data: [],
              url: getURL(),
                formItemLayout,
                formTwoLayout,
                roomModal: false,
                loadingExport:false,
                checkorder:{},
                loading:true,
                searchText: '',
                newReservation: {},
                searchInput: null,
                exportFormat: '',
                action:undefined,
                IMG_URL,
                selectedRowKeys: [],
                permissions:[],
                vars: {},
                actions: [],
                concepts:[],
                productsinorder: false,
                locale2,
                years:[],
                selectedYear:  parseInt(new Date().getFullYear()),
                selectedMonth: parseInt(new Date().getMonth())+1,
                reservationModal:false,
                filteredprice:false,
                filteredabbprice:false,
                expand: false,
                form: this.$form.createForm(this, { name: 'advanced_search' }),
                roleops: [],
                locale,
                order_statuses: [
                    {"value": "pending","label": "Objednávka"},
                    {"value": "payment_recieved","label": "Prijatá platba"},
                    {"value": "completed","label": "Kompletná"},
                    {"value": "abandoned","label": "Opustená objednávka"},
                    {"value": "in_checkout","label": "Na platobnej bráne"},
                    {"value": "storno","label": "Stornovaná objednávka"},
                    ],
                    order_payments: [
                    {"value": "cod","label": "Platba na mieste"},
                    {"value": "card","label": "Platba kartou"},
                    {"value": "bank","label": "Platba Bankovým prevodom"},
                    ],
                colorsto: JSON.parse(localStorage.colors),
                user: JSON.parse(localStorage.user),
                loading: false,
                 dateFormat: 'DD.MM.YYYY',
                pagination: {
                    defaultPageSize: 10000
                },
                columns2: [
                {
                    title: 'č. obj.',
                    dataIndex: 'order_id',
                        width: '5%',
                },
                {
                    title: 'Dátum vytvorenia',
                    dataIndex: 'created',
                    scopedSlots: {customRender: 'date'},
                    width: '10%'
                },
                {
                    title: 'Zákazník',
                    dataIndex: 'name',
                    scopedSlots: {customRender: 'user'},
                    width: '10%',
                },
                {
                    title: 'Poznámka',
                    dataIndex: 'note',
                    width: '10%',
                },
                {
                    align: 'right',
                    scopedSlots: {customRender: 'name'},
                }
                ],
                columns: [
                    {
                        title: '#',
                        dataIndex: 'order_id',
                        width: '5%'
                    },  {
                        title: 'Meno',
                        dataIndex: 'name',
                        width: '15%',
                    },
                    {
                        title: 'Kontakt',
                        dataIndex: 'phone',
                        scopedSlots: {customRender: 'phones'},
                    },
                    {
                        title: 'Vytvorené',
                        dataIndex: 'created',
                        scopedSlots: {customRender: 'date'},
                    },
                    {
                        title: 'Typ platby',
                        dataIndex: 'payment_method',
                        width: '10%',
                        scopedSlots: {customRender: 'payment'},
                    },   {
                        title: 'Poštovné',
                        dataIndex: 'shipping',
                        width: '10%',
                        scopedSlots: {customRender: 'shipping'},
                    },
                    {
                        title: 'Objednávka',
                        dataIndex: 'products',
                        width: '30%',
                        scopedSlots: {customRender: 'products'},
                    },
                    {
                        title: 'Stav',
                        dataIndex: 'order_status',
                        width: '10%',
                        scopedSlots: {customRender: 'status'},
                    },
                    {
                        title: 'Faktúra',
                        dataIndex: 'invoice',
                        width: '10%',
                        scopedSlots: {customRender: 'invoice'},
                    },  {
                        title: 'Suma',
                        dataIndex: 'price',
                        width: '15%',
                        scopedSlots: {customRender: 'prices'},
                    },
                    {
                        align: 'right',
                        scopedSlots: {customRender: 'name'},
                    }
                ],
            };

        },
computed: {
         rowSelection() {
                const { selectedRowKeys } = this;
                return {
                    selectedRowKeys,
                    onChange: this.onSelectChange,
                    hideDefaultSelections: true,
                    onSelection: this.onSelection,
                };
            },
  },
        mounted() {
            dataService.axiosFetch("roles").then(results => {



this.actions.push({"value":"export","label":"Exportovať do PDF"});


                results.forEach(obj => {
                    var iid = obj.id;
                    var vval = obj.title;
                    this.roleops[iid] = vval;


                });
            });
            this.$nextTick(() => {
              this.PostFetch();

            })

        },
        methods: {
          redo(order_id,invoice_id,created)
          {

          let year = this.formatYear(created);

 dataService.axiosFetch("invoice/" + order_id + "/true/" + invoice_id + "/" + year);

          },
          onSelectChange(selectedRowKeys) {
                this.selectedRowKeys = selectedRowKeys;
            },handleFilter(e) {
    e.preventDefault();
    this.PostFetch();
},
handleResetMF() {
      this.form.resetFields();
      this.PostFetch();
    },
     PostFetch()
    {
this.form.validateFields((err, values) => {
        if (!err) {
   this.loading = true;
   this.filteredprice = 0;
   this.filteredabbprice = 0;




                dataService.axiosPost('getallorders',values).then(results => {
                    this.data = results.data.data;

                    this.loading = false;
var roleops = 0;
var roleops2 = 0;


                    this.filteredprice = roleops;

                    this.filteredabbprice = roleops2;

                });
        }
        });
    },
          exposeYear()
    {

let years = [];
var fromyear = parseInt(new Date().getFullYear()) - 10;
var toyear = parseInt(new Date().getFullYear());

var date = new Date();
for(let year = fromyear; year <= toyear; year++){ years.push(year) }

return years;
    },
            resendEmails(order_id)
            {
                dataService.axiosFetch('sendall',order_id).then(() => {

                });
            },
            formatDate: function (dt) {
                return moment.unix(dt).format('D.M.YYYY H:mm')


            },
            exportInvoices()
            {
this.form.validateFields((err, values) => {


if(this.selectedRowKeys)
values.selectedRowKeys = this.selectedRowKeys;


dataService.axiosPost('export/invoices/'+this.exportFormat,values).then(results => {
                  if(this.exportFormat == 'pohoda')
                  {
    let byte = results.data[0];
    var reportName = 'export.xml';
                    var blob = new Blob([byte], {type: "application/xml"});
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    var fileName = reportName;
    link.download = fileName;
    link.click();
                    }
                    else
                    {
                        const byteCharacters = atob(results.data.data.file);
                        const byteNumbers = new Array(byteCharacters.length);
for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
}
                        const byteArray = new Uint8Array(byteNumbers);
    var reportNames = results.data.data.filename;
       var blobs = new Blob([byteArray], {type: "application/pdf"});
    var links = document.createElement('a');
    links.href = window.URL.createObjectURL(blobs);
    var fileNames= reportNames;
    links.download = fileNames;
    links.click();
                    }
                });
            });
            },

            doAction()
                {
                    if(this.action)
                    {
if(this.form)
{
                      this.form.validateFields((err, values) => {
this.loadingExport = true;
                    dataService.axiosPost('export/orders/' + this.action, { 'orders' : this.rowSelection.selectedRowKeys, 'month': values.selectedMonth, 'year':values.selectedYear}).then((results) => {
                        this.rowSelection.selectedRowKeys = [];
                        this.selectedRowKeys =  [];
                        if(this.action === "export")
                        {
                          this.forcePDFDownload(results.data.data);
                        }
                        if(this.action === "exportxls")
                        {
                          this.forceXLSDownload(results.data.data);
                        }
this.loadingExport = false;
                    });
                   });
                   }
                  }
                },
                forceXLSDownload(response){

var arrBuffer = this.base64ToArrayBuffer(response.file);
               const url = window.URL.createObjectURL(new Blob([arrBuffer], {type:"application/vnd.ms-excel"}))


               const link = document.createElement('a')
               link.href = url
               link.setAttribute('download', response.filename) //or any other extension
               document.body.appendChild(link)
               link.click()
           },
                forcePDFDownload(response){

var arrBuffer = this.base64ToArrayBuffer(response.file);
               const url = window.URL.createObjectURL(new Blob([arrBuffer], {type:"application/pdf"}))


               const link = document.createElement('a')
               link.href = url
               link.setAttribute('download', response.filename) //or any other extension
               document.body.appendChild(link)
               link.click()
           },
            formatYear: function (dt) {
                return moment.unix(dt).format('YYYY')

            },
            handleSearch(selectedKeys, confirm) {
                confirm();
                this.searchText = selectedKeys[0];

            },

            handleReset(clearFilters) {
                clearFilters();
                this.searchText = '';
            },
            fetch() {
                this.loading = true;
                dataService.axiosFetch('getallorders').then(results => {
                    this.data = results;
                    this.loading = false;

                });
            }

        },
    };
</script>

<style>
    ul.ant-pagination {
        margin-top: 0;
        margin-bottom: 1em;
    }


    .product {
        width: 100%;
        clear: both
    }

    .product h4 {
        margin: 0;
        font-size: 12px;
    }
    .paid
    {font-size:12px;
    font-weight:bold;}

    .product h4.type-product {
        font-size: 12px;
    }

    .product h4.type-voucher {
        display: none
    }

    .wrong1 {
        color: red !important
    }

    .product h4 span {
        float: right;
        font-size: 12px;
        padding-top: 5px;
    }

    .product p {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 2px;
        color: #000000;

    }


    .product:not(.item-0) h4 {
        padding-top: 2px;
    }


.abandoned
{color:#ccc;}
    .ant-pagination-item-link.ant-pagination-disabled a {
        color: #8898aa;
        pointer-events: none;
        cursor: auto;
        background-color: #fff;
        border-color: #dee2e6;
    }

</style>
